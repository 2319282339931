import React from 'react';
import { Check } from 'lucide-react';
import { useScrollAnimation } from '../../utils/useScrollAnimation';
import { useLanguage } from '../../i18n/LanguageContext';

export function Pricing() {
  const titleRef = useScrollAnimation({ animation: 'fade-up' });
  const { t } = useLanguage();

  return (
    <section className="py-20 bg-gray-900/50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div ref={titleRef} className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
            {t('pricing', 'title')}
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            {t('pricing', 'subtitle')}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <PriceCard
            title="Starter"
            price="0"
            description="Parfait pour tester la plateforme"
            features={[
              "1 application mobile",
              "Templates de base",
              "Personnalisation limitée",
              "Support communautaire"
            ]}
            delay={0}
          />
          <PriceCard
            title="Pro"
            price="29"
            description="Pour les entrepreneurs sérieux"
            features={[
              "3 applications mobiles",
              "Tous les templates",
              "Personnalisation complète",
              "Support prioritaire",
              "Publication sur les stores",
              "Analytics avancés"
            ]}
            highlighted={true}
            delay={200}
          />
          <PriceCard
            title="Enterprise"
            price="99"
            description="Pour les grandes entreprises"
            features={[
              "Applications illimitées",
              "Templates personnalisés",
              "Support dédié 24/7",
              "API personnalisée",
              "Formation équipe",
              "SLA garanti"
            ]}
            delay={400}
          />
        </div>
      </div>
    </section>
  );
}

function PriceCard({ 
  title, 
  price, 
  description, 
  features, 
  highlighted = false,
  delay
}: { 
  title: string;
  price: string;
  description: string;
  features: string[];
  highlighted?: boolean;
  delay: number;
}) {
  const ref = useScrollAnimation({ animation: 'fade-up', delay });
  const { t } = useLanguage();

  return (
    <div 
      ref={ref}
      className={`p-8 rounded-xl transition-all duration-300 hover:scale-105 ${
        highlighted 
          ? 'bg-purple-600 border-2 border-purple-400 transform scale-105' 
          : 'bg-gray-800 border border-gray-700'
      }`}
    >
      <h3 className="text-2xl font-bold text-white mb-2">{title}</h3>
      <div className="mb-4">
        <span className="text-4xl font-bold text-white">${price}</span>
        <span className="text-gray-400">{t('pricing', 'perMonth')}</span>
      </div>
      <p className={`mb-6 ${highlighted ? 'text-purple-200' : 'text-gray-400'}`}>
        {description}
      </p>
      <ul className="space-y-3 mb-8">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center gap-2">
            <Check className={`w-5 h-5 ${
              highlighted ? 'text-purple-200' : 'text-purple-400'
            }`} />
            <span className={highlighted ? 'text-white' : 'text-gray-300'}>
              {feature}
            </span>
          </li>
        ))}
      </ul>
      <button className={`w-full py-3 rounded-lg font-medium transition-all duration-300 hover:scale-105 ${
        highlighted
          ? 'bg-white text-purple-600 hover:bg-gray-100'
          : 'bg-purple-600 text-white hover:bg-purple-700'
      }`}>
        {t('pricing', 'startNow')}
      </button>
    </div>
  );
}