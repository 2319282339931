import { Translations } from './types';

export const translations: Translations = {
  fr: {
    nav: {
      generate: "Générer",
      marketplace: "Marketplace",
      customize: "Personnaliser",
      getStarted: "Commencer"
    },
    hero: {
      poweredBy: "Propulsé par l'IA",
      title: "Créez votre application mobile en",
      titleHighlight: "moins de 30 secondes",
      description: "Décrivez votre application en français naturel, notre IA s'occupe du reste. Compatible avec Play Store, App Store et AppGallery.",
      inputPlaceholder: "Décrivez l'application de vos rêves...",
      generate: "Générer",
      example: "Exemple: "
    },
    features: {
      title: "Fonctionnalités principales",
      subtitle: "Tout ce dont vous avez besoin pour créer et gérer votre application mobile sans connaissances techniques."
    },
    showcase: {
      badge: "Applications Générées",
      title: "Applications générées par nos utilisateurs",
      subtitle: "Découvrez des exemples d'applications créées en quelques secondes avec notre plateforme",
      cta: "Rejoignez plus de 10,000+ développeurs qui font confiance à TurboApp",
      startFree: "Commencer gratuitement"
    },
    pricing: {
      title: "Tarifs simples et transparents",
      subtitle: "Choisissez le plan qui correspond le mieux à vos besoins",
      perMonth: "/mois",
      startNow: "Commencer"
    }
  },
  en: {
    nav: {
      generate: "Generate",
      marketplace: "Marketplace",
      customize: "Customize",
      getStarted: "Get Started"
    },
    hero: {
      poweredBy: "AI-Powered",
      title: "Create your mobile app in",
      titleHighlight: "less than 30 seconds",
      description: "Describe your app in natural language, our AI takes care of the rest. Compatible with Play Store, App Store, and AppGallery.",
      inputPlaceholder: "Describe the app of your dreams...",
      generate: "Generate",
      example: "Example: "
    },
    features: {
      title: "Key Features",
      subtitle: "Everything you need to create and manage your mobile app without technical knowledge."
    },
    showcase: {
      badge: "Generated Apps",
      title: "Applications generated by our users",
      subtitle: "Discover examples of apps created in seconds with our platform",
      cta: "Join over 10,000+ developers who trust TurboApp",
      startFree: "Start for free"
    },
    pricing: {
      title: "Simple and transparent pricing",
      subtitle: "Choose the plan that best fits your needs",
      perMonth: "/month",
      startNow: "Start Now"
    }
  }
};