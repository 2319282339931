import React from 'react';
import { Sparkles, Twitter, Github, Linkedin } from 'lucide-react';

export function Footer() {
  return (
    <footer className="bg-gray-900 border-t border-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1">
            <div className="flex items-center mb-4">
              <Sparkles className="w-8 h-8 text-purple-500" />
              <span className="ml-2 text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">
                TurboApp
              </span>
            </div>
            <p className="text-gray-400">
              Générez des applications mobiles professionnelles en quelques secondes.
            </p>
            <div className="flex space-x-4 mt-4">
              <SocialLink icon={<Twitter className="w-5 h-5" />} href="#" />
              <SocialLink icon={<Github className="w-5 h-5" />} href="#" />
              <SocialLink icon={<Linkedin className="w-5 h-5" />} href="#" />
            </div>
          </div>
          
          <div className="col-span-1">
            <h3 className="text-white font-semibold mb-4">Produit</h3>
            <FooterLinks
              links={[
                "Fonctionnalités",
                "Tarifs",
                "Templates",
                "Marketplace"
              ]}
            />
          </div>
          
          <div className="col-span-1">
            <h3 className="text-white font-semibold mb-4">Ressources</h3>
            <FooterLinks
              links={[
                "Documentation",
                "Tutoriels",
                "Blog",
                "API"
              ]}
            />
          </div>
          
          <div className="col-span-1">
            <h3 className="text-white font-semibold mb-4">Entreprise</h3>
            <FooterLinks
              links={[
                "À propos",
                "Contact",
                "Carrières",
                "Mentions légales"
              ]}
            />
          </div>
        </div>
        
        <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>© 2024 TurboApp. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
}

function SocialLink({ icon, href }: { icon: React.ReactNode; href: string }) {
  return (
    <a
      href={href}
      className="w-10 h-10 bg-gray-800 rounded-full flex items-center justify-center text-gray-400 hover:bg-purple-600 hover:text-white transition-colors"
    >
      {icon}
    </a>
  );
}

function FooterLinks({ links }: { links: string[] }) {
  return (
    <ul className="space-y-2">
      {links.map((link, index) => (
        <li key={index}>
          <a href="#" className="text-gray-400 hover:text-white transition-colors">
            {link}
          </a>
        </li>
      ))}
    </ul>
  );
}