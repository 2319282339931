import React, { useEffect, useRef, useState } from 'react';
import { Zap, Clock, Store, Send, Sparkles } from 'lucide-react';
import { useLanguage } from '../../i18n/LanguageContext';

const EXAMPLE_PROMPTS = {
  fr: [
    "Crée une application de livraison de repas avec paiement Stripe",
    "Génère une app de réservation de rendez-vous beauté",
    "Fais-moi une application de e-commerce avec panier",
  ],
  en: [
    "Create a food delivery app with Stripe payment",
    "Generate a beauty appointment booking app",
    "Make me an e-commerce app with shopping cart",
  ]
};

export function Hero() {
  const heroRef = useRef<HTMLDivElement>(null);
  const [prompt, setPrompt] = useState('');
  const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const { t, language } = useLanguage();

  useEffect(() => {
    const handleScroll = () => {
      if (heroRef.current) {
        const scrolled = window.scrollY;
        const rate = scrolled * 0.5;
        heroRef.current.style.transform = `translateY(${rate}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Typing animation effect
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const currentPrompt = EXAMPLE_PROMPTS[language][currentPromptIndex];
    
    if (isTyping) {
      if (prompt.length < currentPrompt.length) {
        timeout = setTimeout(() => {
          setPrompt(currentPrompt.slice(0, prompt.length + 1));
        }, 50);
      } else {
        timeout = setTimeout(() => {
          setIsTyping(false);
        }, 2000);
      }
    } else {
      if (prompt.length > 0) {
        timeout = setTimeout(() => {
          setPrompt(prompt.slice(0, -1));
        }, 30);
      } else {
        setCurrentPromptIndex((prev) => (prev + 1) % EXAMPLE_PROMPTS[language].length);
        setIsTyping(true);
      }
    }

    return () => clearTimeout(timeout);
  }, [prompt, isTyping, currentPromptIndex, language]);

  return (
    <div className="relative min-h-screen flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0 bg-gray-900">
        <div className="absolute inset-0 bg-gradient-to-b from-cyan-500/10 via-purple-500/5 to-gray-900" />
      </div>
      
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-cyan-500 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob" />
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-2000" />
        <div className="absolute top-40 left-40 w-80 h-80 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-4000" />
      </div>

      <div ref={heroRef} className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <div className="text-center">
          <div className="flex items-center justify-center gap-2 mb-6">
            <span className="px-4 py-2 rounded-full bg-cyan-500/10 text-cyan-400 text-sm font-medium animate-float flex items-center">
              <Sparkles className="w-4 h-4 mr-2" />
              {t('hero', 'poweredBy')}
            </span>
          </div>
          
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 text-glow">
            {t('hero', 'title')}{' '}
            <span className="bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-500 text-transparent bg-clip-text animate-gradient">
              {t('hero', 'titleHighlight')}
            </span>
          </h1>
          
          <p className="text-xl text-gray-300 mb-12 max-w-3xl mx-auto">
            {t('hero', 'description')}
          </p>

          <div className="max-w-3xl mx-auto mb-12">
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-cyan-500 via-blue-500 to-purple-500 rounded-lg blur opacity-25 group-hover:opacity-75 transition duration-1000 group-hover:duration-200" />
              <div className="relative flex items-center bg-gray-900 rounded-lg p-2">
                <input
                  type="text"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder={t('hero', 'inputPlaceholder')}
                  className="w-full bg-transparent text-white placeholder-gray-400 px-4 py-3 focus:outline-none"
                />
                <button className="flex-shrink-0 bg-gradient-to-r from-cyan-600 to-blue-600 hover:from-cyan-500 hover:to-blue-500 text-white rounded-lg px-6 py-3 flex items-center gap-2 transition-all duration-300 hover:scale-105">
                  <Send className="w-5 h-5" />
                  <span>{t('hero', 'generate')}</span>
                </button>
              </div>
            </div>
            <p className="text-gray-400 mt-2 text-sm">
              {t('hero', 'example')}"{EXAMPLE_PROMPTS[language][currentPromptIndex]}"
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-20">
            <FeatureCard 
              icon={<Zap className="w-6 h-6 text-cyan-400" />}
              title="Ultra rapide"
              description="Générez votre application en moins de 30 secondes"
            />
            <FeatureCard 
              icon={<Store className="w-6 h-6 text-cyan-400" />}
              title="Multi-stores"
              description="Publiez sur tous les stores en quelques clics"
            />
            <FeatureCard 
              icon={<Clock className="w-6 h-6 text-cyan-400" />}
              title="Personnalisable"
              description="Modifiez votre app selon vos besoins"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function FeatureCard({ icon, title, description }: { icon: React.ReactNode; title: string; description: string }) {
  return (
    <div className="p-6 glass-effect rounded-xl border border-gray-700 hover:border-cyan-500/50 transition-all duration-300 hover:scale-105 group">
      <div className="w-12 h-12 bg-gray-800/50 rounded-lg flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300">
        {icon}
      </div>
      <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
      <p className="text-gray-400">{description}</p>
    </div>
  );
}