import React from 'react';
import { Smartphone, PaintBucket, CreditCard, Store, Gauge, Lock } from 'lucide-react';
import { useScrollAnimation } from '../../utils/useScrollAnimation';
import { useLanguage } from '../../i18n/LanguageContext';

export function Features() {
  const titleRef = useScrollAnimation({ animation: 'fade-up' });
  const { t } = useLanguage();
  
  return (
    <section className="py-20 bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div ref={titleRef} className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
            {t('features', 'title')}
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            {t('features', 'subtitle')}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            {
              icon: <Smartphone className="w-6 h-6" />,
              title: "Interface intuitive",
              description: "Créez votre application avec une interface drag & drop simple et intuitive",
              delay: 0
            },
            {
              icon: <PaintBucket className="w-6 h-6" />,
              title: "Personnalisation complète",
              description: "Modifiez les couleurs, textes et mises en page selon vos besoins",
              delay: 100
            },
            {
              icon: <CreditCard className="w-6 h-6" />,
              title: "Paiements intégrés",
              description: "Ajoutez facilement différents moyens de paiement à votre application",
              delay: 200
            },
            {
              icon: <Store className="w-6 h-6" />,
              title: "Publication multi-stores",
              description: "Publiez sur Play Store, App Store et AppGallery en quelques clics",
              delay: 300
            },
            {
              icon: <Gauge className="w-6 h-6" />,
              title: "Performances optimisées",
              description: "Applications rapides et fluides sur tous les appareils",
              delay: 400
            },
            {
              icon: <Lock className="w-6 h-6" />,
              title: "Sécurité renforcée",
              description: "Protection des données et conformité aux standards de sécurité",
              delay: 500
            }
          ].map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
}

function FeatureCard({ 
  icon, 
  title, 
  description, 
  delay 
}: { 
  icon: React.ReactNode; 
  title: string; 
  description: string;
  delay: number;
}) {
  const ref = useScrollAnimation({ animation: 'fade-up', delay });
  
  return (
    <div ref={ref} className="p-6 bg-gray-800 rounded-xl hover:bg-gray-800/80 transition-all duration-300 hover:scale-105">
      <div className="w-12 h-12 bg-purple-500/10 rounded-lg flex items-center justify-center mb-4 text-purple-400">
        {icon}
      </div>
      <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
      <p className="text-gray-400">{description}</p>
    </div>
  );
}