import React, { createContext, useContext, useState, useEffect } from 'react';
import { translations } from './translations';
import { Language } from './types';

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  t: (section: keyof typeof translations.en, key: string) => string;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState<Language>('en');

  useEffect(() => {
    // Detect user's preferred language
    const userLang = navigator.language.split('-')[0];
    if (userLang === 'fr') {
      setLanguage('fr');
    }
  }, []);

  const t = (section: keyof typeof translations.en, key: string): string => {
    return translations[language][section][key as keyof typeof translations.en[typeof section]];
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}