import React, { useEffect, useState } from 'react';
import { Zap, Smartphone, Store, Palette, Github } from 'lucide-react';
import { useLanguage } from '../../i18n/LanguageContext';
import { LanguageSwitch } from './LanguageSwitch';

export function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const { t } = useLanguage();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed top-0 w-full z-50 transition-all duration-300 ${
      scrolled ? 'bg-gray-900/80 backdrop-blur-lg border-b border-gray-800' : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center group">
            <div className="relative">
              <Zap className="w-8 h-8 text-cyan-500 animate-pulse" />
              <div className="absolute inset-0 bg-cyan-500 blur-xl opacity-40 group-hover:opacity-60 transition-opacity" />
            </div>
            <span className="ml-2 text-xl font-bold bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-500 text-transparent bg-clip-text animate-gradient">
              TurboApp
            </span>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            <NavLink icon={<Smartphone className="w-4 h-4" />} text={t('nav', 'generate')} />
            <NavLink icon={<Store className="w-4 h-4" />} text={t('nav', 'marketplace')} />
            <NavLink icon={<Palette className="w-4 h-4" />} text={t('nav', 'customize')} />
            <LanguageSwitch />
            <a href="https://github.com" 
               className="text-gray-300 hover:text-white flex items-center gap-2 transition-colors">
              <Github className="w-4 h-4" />
              <span>Github</span>
            </a>
            <button className="bg-gradient-to-r from-cyan-600 to-blue-600 hover:from-cyan-500 hover:to-blue-500 text-white px-4 py-2 rounded-lg transition-all duration-300 hover:scale-105">
              {t('nav', 'getStarted')}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

function NavLink({ icon, text }: { icon: React.ReactNode; text: string }) {
  return (
    <a href="#" className="text-gray-300 hover:text-white flex items-center gap-2 transition-colors group">
      <span className="group-hover:scale-110 transition-transform duration-300">{icon}</span>
      <span>{text}</span>
    </a>
  );
}