import React, { useState } from 'react';
import { Star, ArrowRight } from 'lucide-react';
import { useScrollAnimation } from '../../utils/useScrollAnimation';
import { useLanguage } from '../../i18n/LanguageContext';

const APPS = {
  fr: [
    {
      name: "FoodDelivery Pro",
      description: "Application de livraison de repas",
      rating: 4.9,
      image: "https://images.unsplash.com/photo-1622771956449-8f5a8e929c9e?auto=format&fit=crop&q=80&w=600&h=800",
      features: ["Paiement intégré", "Géolocalisation", "Chat en direct"],
      color: "from-orange-500 to-red-500"
    },
    {
      name: "FitTracker Elite",
      description: "Application de fitness et bien-être",
      rating: 4.8,
      image: "https://images.unsplash.com/photo-1611845642761-f2ea1c5a8e31?auto=format&fit=crop&q=80&w=600&h=800",
      features: ["Suivi d'activité", "Plans personnalisés", "Statistiques"],
      color: "from-blue-500 to-cyan-500"
    },
    {
      name: "ShopMaster",
      description: "E-commerce nouvelle génération",
      rating: 4.9,
      image: "https://images.unsplash.com/photo-1611162617213-7d7a39e9b1d7?auto=format&fit=crop&q=80&w=600&h=800",
      features: ["Catalogue produits", "Panier", "Wishlist"],
      color: "from-purple-500 to-pink-500"
    }
  ],
  en: [
    {
      name: "FoodDelivery Pro",
      description: "Food delivery application",
      rating: 4.9,
      image: "https://images.unsplash.com/photo-1622771956449-8f5a8e929c9e?auto=format&fit=crop&q=80&w=600&h=800",
      features: ["Integrated payment", "Geolocation", "Live chat"],
      color: "from-orange-500 to-red-500"
    },
    {
      name: "FitTracker Elite",
      description: "Fitness and wellness application",
      rating: 4.8,
      image: "https://images.unsplash.com/photo-1611845642761-f2ea1c5a8e31?auto=format&fit=crop&q=80&w=600&h=800",
      features: ["Activity tracking", "Custom plans", "Statistics"],
      color: "from-blue-500 to-cyan-500"
    },
    {
      name: "ShopMaster",
      description: "Next-gen e-commerce",
      rating: 4.9,
      image: "https://images.unsplash.com/photo-1611162617213-7d7a39e9b1d7?auto=format&fit=crop&q=80&w=600&h=800",
      features: ["Product catalog", "Shopping cart", "Wishlist"],
      color: "from-purple-500 to-pink-500"
    }
  ]
};

export function Showcase() {
  const titleRef = useScrollAnimation({ animation: 'fade-up' });
  const ctaRef = useScrollAnimation({ animation: 'fade-up', delay: 200 });
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { t, language } = useLanguage();

  return (
    <section className="py-20 bg-gray-900 relative overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-purple-500/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob" />
        <div className="absolute top-1/3 right-1/4 w-96 h-96 bg-cyan-500/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-2000" />
        <div className="absolute -bottom-8 left-1/2 w-96 h-96 bg-pink-500/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-4000" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div ref={titleRef} className="text-center mb-16">
          <div className="inline-block">
            <span className="inline-flex items-center px-4 py-2 rounded-full bg-purple-500/10 text-purple-400 text-sm font-medium mb-4">
              <Star className="w-4 h-4 mr-2" />
              {t('showcase', 'badge')}
            </span>
          </div>
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-cyan-400">
            {t('showcase', 'title')}
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto text-lg">
            {t('showcase', 'subtitle')}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {APPS[language].map((app, index) => (
            <AppCard 
              key={index} 
              {...app} 
              delay={index * 200}
              isHovered={hoveredIndex === index}
              onHover={() => setHoveredIndex(index)}
              onLeave={() => setHoveredIndex(null)}
            />
          ))}
        </div>

        <div ref={ctaRef} className="mt-16 text-center">
          <div className="inline-block p-8 glass-effect rounded-2xl">
            <p className="text-2xl font-semibold text-white mb-8 bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">
              {t('showcase', 'cta')}
            </p>
            <button className="group px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-500 hover:to-pink-500 text-white rounded-lg font-medium transition-all duration-300 hover:scale-105 flex items-center justify-center mx-auto">
              <span>{t('showcase', 'startFree')}</span>
              <ArrowRight className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

function AppCard({ 
  name, 
  description, 
  rating, 
  image, 
  features,
  color,
  delay,
  isHovered,
  onHover,
  onLeave
}: {
  name: string;
  description: string;
  rating: number;
  image: string;
  features: string[];
  color: string;
  delay: number;
  isHovered: boolean;
  onHover: () => void;
  onLeave: () => void;
}) {
  const ref = useScrollAnimation({ animation: 'fade-up', delay });

  return (
    <div 
      ref={ref} 
      className="group relative bg-gray-800 rounded-xl overflow-hidden transition-all duration-500 hover:scale-105"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <div className="relative h-[500px] overflow-hidden">
        <div className={`absolute inset-0 bg-gradient-to-t ${color} mix-blend-overlay opacity-60 group-hover:opacity-40 transition-opacity duration-500`} />
        <img 
          src={image} 
          alt={name}
          className={`w-full h-full object-cover transform transition-transform duration-700 ${
            isHovered ? 'scale-110' : 'scale-100'
          }`}
        />
      </div>

      <div className={`absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-gray-900 via-gray-900/95 to-transparent transition-transform duration-500 ${
        isHovered ? 'translate-y-0' : 'translate-y-4'
      }`}>
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-xl font-bold text-white">{name}</h3>
          <div className="flex items-center">
            <Star className="w-5 h-5 text-yellow-400 fill-current animate-pulse" />
            <span className="ml-1 text-white">{rating}</span>
          </div>
        </div>
        <p className="text-gray-300 mb-4">{description}</p>
        <div className="flex flex-wrap gap-2">
          {features.map((feature, index) => (
            <span 
              key={index}
              className={`px-3 py-1 bg-gray-700/50 backdrop-blur-sm rounded-full text-sm text-gray-300 transition-all duration-300 ${
                isHovered ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
              }`}
              style={{ transitionDelay: `${index * 100}ms` }}
            >
              {feature}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}